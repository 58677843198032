// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import api from "@src/api/config"

export const getBusinessClients = createAsyncThunk(
  "dashboard/tax_data",
  async ({ params = {} }) => {
    const response = await api.get(`api/dashboard/tax_data`, { params })
    return { data: response.data, params }
  }
)

export const getProductCerts = createAsyncThunk(
  "dashboard/products_cert",
  async ({ params = {} }) => {
    const response = await api.get(
      `api/dashboard-global/certificates-products`,
      { params }
    )
    return { data: response.data, params }
  }
)

export const ClientDashboardSlice = createSlice({
  name: "clients-dashboard",
  initialState: {
    businessClients: [],
    businessClientsLoading: false,
    productCerts: [],
    productCertsLoading: false
  },
  reducers: {
    clearDashboardData: (state) => {
      state.businessClients = []
      state.productCerts = []
    }
  },
  extraReducers: (builder) => {
    //getBusinessClients
    builder.addCase(getBusinessClients.rejected, (state) => {
      state.businessClientsLoading = false
    })
    builder.addCase(getBusinessClients.pending, (state) => {
      state.businessClientsLoading = true
    })
    builder.addCase(getBusinessClients.fulfilled, (state, action) => {
      state.businessClientsLoading = false
      state.businessClients = action.payload.data.data
    })
    //getProductCerts
    builder.addCase(getProductCerts.rejected, (state) => {
      state.productCertsLoading = false
    })
    builder.addCase(getProductCerts.pending, (state) => {
      state.productCertsLoading = true
    })
    builder.addCase(getProductCerts.fulfilled, (state, action) => {
      state.productCertsLoading = false
      state.productCerts = action.payload.data
    })
  }
})

export const clientDashboard = ClientDashboardSlice.actions
export default ClientDashboardSlice.reducer
