// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import api from "@src/api/config"

export const getExchangeRates = createAsyncThunk(
  "dashboard/exchange-rate",
  async (params) => {
    const response = await api.get(`api/dashboard/exchange-rate`, { params })
    return { data: response.data, params }
  }
)

export const getCashing = createAsyncThunk(
  "dashboard/cashing",
  async ({ params = {}, global = false }) => {
    const url = `api/${global ? "dashboard-global" : "dashboard"}/cashing`

    const response = await api.get(url, { params })
    return { data: response.data, params }
  }
)

export const getSales = createAsyncThunk(
  "dashboard/global/sales",
  async ({ params = {}, global = false }) => {
    const url = `api/${global ? "dashboard-global" : "dashboard"}/sales`

    const response = await api.get(url, { params })
    return { data: response.data, params }
  }
)

export const getCertificates = createAsyncThunk(
  "dashboard/certificates",
  async (params = {}) => {
    const response = await api.get(`api/dashboard/certificates`, { params })
    return { data: response.data, params }
  }
)

export const getCertificatesClient = createAsyncThunk(
  "dashboard/certificates-client",
  async ({ params = {} }) => {
    const response = await api.get(`api/dashboard/certificates-client`, {
      params
    })
    return { data: response.data, params }
  }
)

export const getGlobalCertificates = createAsyncThunk(
  "dashboard/global/certificates",
  async (params = {}, { getState }) => {
    const state = getState()
    const businessLines = state.businessLines?.businessLineSelected?.id
    params.business_line = businessLines
    const response = await api.post(`api/dashboard-global/certificates`, params)
    return { data: response.data, params }
  }
)

export const DashboardFiltersSlice = createSlice({
  name: "dashboard-filters",
  initialState: {
    searchOption: "",
    filterOption: "yearly",
    businessLineFilter: 1,
    productFilter: null,
    providerFilter: null,
    certStatusFilter: "issued",
    filterCertsStatus: [],
    customDate: null,
    search: "",
    totalSales: 0,
    sales: {
      labels: [],
      datasets: [
        {
          data: [],
          label: "Suma Asegurada",
          borderColor: "#0D6EFD",
          lineTension: 0.5,
          pointStyle: "circle",
          backgroundColor: "#0D6EFD",
          fill: false,
          pointRadius: 1,
          pointHoverRadius: 5,
          pointHoverBorderWidth: 5,
          pointBorderColor: "transparent",
          pointHoverBorderColor: "#fff",
          pointHoverBackgroundColor: "#0D6EFD",
          pointShadowOffsetX: 1,
          pointShadowOffsetY: 1,
          pointShadowBlur: 5
        },
        {
          data: [],
          label: "Prima neta",
          borderColor: "#00CFE8",
          lineTension: 0.5,
          pointStyle: "circle",
          backgroundColor: "#00CFE8",
          fill: false,
          pointRadius: 1,
          pointHoverRadius: 5,
          pointHoverBorderWidth: 5,
          pointBorderColor: "transparent",
          pointHoverBorderColor: "#fff",
          pointHoverBackgroundColor: "#00CFE8",
          pointShadowOffsetX: 1,
          pointShadowOffsetY: 1,
          pointShadowBlur: 5
        }
      ]
    },
    exchange_rate: [],
    clientsBySellerDash: {},
    certificates: [],
    certificatesDetails: [],
    totalCertificates: 0,
    businessClients: [],
    cashing: null,
    certificatesClient: null,
    berkleyMxn: 0,
    berkleyUsd: 0,
    zurichMxn: 0,
    zurichUsd: 0,
    salesAvg: 0,
    netPremiumAvg: 0,
    totalNetPremium: 0,
    //Loadings
    salesLoading: false,
    certificatesLoading: false,
    cashingLoading: true,
    clientsBySellerDashLoading: true,
    certificatesClientLoading: false,
    businessClientsLoading: false,
    exchangeRateLoading: false
  },
  reducers: {
    setSearchOption: (state, action) => {
      state.searchOption = action.payload
    },
    setFilterOption: (state, action) => {
      state.filterOption = action.payload
    },
    setCertStatusFilter: (state, action) => {
      state.certStatusFilter = action.payload
    },
    setCertsStatusFilter: (state, action) => {
      state.filterCertsStatus = action.payload
    },
    setBusinessLineFilter: (state, action) => {
      state.productFilter = null
      state.businessLineFilter = action.payload
    },
    setProductFilter: (state, action) => {
      state.businessLineFilter = null
      state.productFilter = action.payload
    },
    setProviderFilter: (state, action) => {
      state.providerFilter = action.payload
    },
    setSearch: (state, action) => {
      state.search = action.payload
    },
    setCustomDate: (state, action) => {
      state.customDate = action.payload
    },
    clearDashboardData: (state) => {
      state.searchOption = ""
      state.filterOption = "yearly"
      state.filterCertsStatus = []
      state.customDate = null
      state.search = ""
      state.totalSales = 0
      state.sales = {
        labels: [],
        datasets: [
          {
            data: [],
            label: "Suma Asegurada",
            borderColor: "#0D6EFD",
            lineTension: 0.5,
            pointStyle: "circle",
            backgroundColor: "#0D6EFD",
            fill: false,
            pointRadius: 1,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 5,
            pointBorderColor: "transparent",
            pointHoverBorderColor: "#fff",
            pointHoverBackgroundColor: "#0D6EFD",
            pointShadowOffsetX: 1,
            pointShadowOffsetY: 1,
            pointShadowBlur: 5
          },
          {
            data: [],
            label: "Prima neta",
            borderColor: "#00CFE8",
            lineTension: 0.5,
            pointStyle: "circle",
            backgroundColor: "#00CFE8",
            fill: false,
            pointRadius: 1,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 5,
            pointBorderColor: "transparent",
            pointHoverBorderColor: "#fff",
            pointHoverBackgroundColor: "#00CFE8",
            pointShadowOffsetX: 1,
            pointShadowOffsetY: 1,
            pointShadowBlur: 5
          }
        ]
      }
      state.invoices = []
      state.payments = {}
      state.clientsBySellerDash = {}
      state.clientsAdmin = {}
      state.comissions = {}
      state.certificates = []
      state.certificatesDetails = []
      state.totalCertificates = 0
      state.businessClients = []
      state.cashing = null
      state.certificatesClient = null
    }
  },
  extraReducers: (builder) => {
    //getExchangeRates
    builder.addCase(getExchangeRates.pending, (state) => {
      state.exchangeRateLoading = true
    })
    builder.addCase(getExchangeRates.rejected, (state) => {
      state.exchangeRateLoading = false
    })
    builder.addCase(getExchangeRates.fulfilled, (state, action) => {
      state.exchangeRateLoading = false
      state.exchange_rate = action.payload.data.return
    })
    //getSales
    builder.addCase(getSales.rejected, (state) => {
      state.salesLoading = false
    })
    builder.addCase(getSales.pending, (state) => {
      state.salesLoading = true
    })
    builder.addCase(getSales.fulfilled, (state, action) => {
      state.salesLoading = false
      if (!action.payload?.data?.data?.providers) {
        //GLOBAL DASHBOARD
        state.sales = action.payload.data
      } else {
        const dashData = action.payload?.data?.data
        // Average
        state.salesLoading = false
        state.salesAvg = dashData?.average ?? 0
        state.netPremiumAvg = dashData?.net_premium_average ?? 0
        state.totalNetPremium = dashData?.total_net_premium ?? 0
        //providers
        const providers = dashData?.providers
        state.berkleyMxn = providers?.berkley_mxn ?? 0
        state.berkleyUsd = providers?.berkley_usd ?? 0
        state.zurichMxn = providers?.zurich_mxn ?? 0
        state.zurichUsd = providers?.zurich_usd ?? 0
        //total
        state.totalSales = dashData?.total ?? 0

        if (dashData.product === 4) {
          state.sales = {
            labels: dashData?.labels ?? [],
            datasets: [
              {
                data: dashData?.amount ?? [],
                label: "Suma Asegurada",
                borderColor: "#0D6EFD",
                lineTension: 0.5,
                pointStyle: "circle",
                backgroundColor: "#0D6EFD",
                fill: false,
                pointRadius: 1,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBorderColor: "transparent",
                pointHoverBorderColor: "#fff",
                pointHoverBackgroundColor: "#0D6EFD",
                pointShadowOffsetX: 1,
                pointShadowOffsetY: 1,
                pointShadowBlur: 5
              }
            ]
          }
        } else {
          state.sales = {
            labels: dashData?.labels ?? [],
            datasets: [
              {
                data: dashData?.amount ?? [],
                label: "Suma Asegurada",
                borderColor: "#0D6EFD",
                lineTension: 0.5,
                pointStyle: "circle",
                backgroundColor: "#0D6EFD",
                fill: false,
                pointRadius: 1,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBorderColor: "transparent",
                pointHoverBorderColor: "#fff",
                pointHoverBackgroundColor: "#0D6EFD",
                pointShadowOffsetX: 1,
                pointShadowOffsetY: 1,
                pointShadowBlur: 5
              },
              {
                data: dashData?.net_premium ?? [],
                label: "Prima neta",
                borderColor: "#00CFE8",
                lineTension: 0.5,
                pointStyle: "circle",
                backgroundColor: "#00CFE8",
                fill: false,
                pointRadius: 1,
                pointHoverRadius: 5,
                pointHoverBorderWidth: 5,
                pointBorderColor: "transparent",
                pointHoverBorderColor: "#fff",
                pointHoverBackgroundColor: "#00CFE8",
                pointShadowOffsetX: 1,
                pointShadowOffsetY: 1,
                pointShadowBlur: 5
              }
            ]
          }
        }
      }
    })
    //getCashing
    builder.addCase(getCashing.rejected, (state) => {
      state.cashingLoading = false
    })
    builder.addCase(getCashing.pending, (state) => {
      state.cashingLoading = true
    })
    builder.addCase(getCashing.fulfilled, (state, action) => {
      state.cashingLoading = false
      state.cashing = action.payload.data
    })
    //getCertificatesClient
    builder.addCase(getCertificatesClient.fulfilled, (state, action) => {
      state.certificatesClientLoading = false
      state.certificatesClient = action.payload.data.data
    })
    builder.addCase(getCertificatesClient.pending, (state) => {
      state.certificatesClientLoading = true
    })
    builder.addCase(getCertificatesClient.rejected, (state) => {
      state.certificatesClientLoading = false
    })
    //getCertificates
    builder.addCase(getCertificates.pending, (state) => {
      state.certificatesLoading = true
    })
    builder.addCase(getCertificates.rejected, (state) => {
      state.certificatesLoading = false
    })
    builder.addCase(getCertificates.fulfilled, (state, action) => {
      // spinner
      state.certificatesLoading = false
      // total
      state.totalCertificates = action.payload.data.total.total

      const data = action.payload.data
      // scheme, data
      state.certificates = [
        {
          label: "Emitido",
          value: data?.issued?.total ? data?.issued?.total : 0,
          color: "#00CFE8"
        },
        {
          label: "Con factura",
          value: data?.withInvoice?.total ? data.withInvoice.total : 0,
          color: "#7367F0"
        },
        {
          label: "Pagado",
          value: data?.withReference?.total ? data.withReference.total : 0,
          color: "#20C997"
        },
        {
          label: "Cancelado",
          value: data?.cancelled?.total ? data.cancelled.total : 0,
          color: "#EA5455"
        },
        {
          label: "Comisionado",
          value: data?.commissioned?.total ? data.commissioned.total : 0,
          color: "#4b4b4b"
        }
      ]
    })
    //getGlobalCertificates
    builder.addCase(getGlobalCertificates.rejected, (state) => {
      state.certificatesLoading = false
    })
    builder.addCase(getGlobalCertificates.pending, (state) => {
      state.certificatesLoading = true
    })
    builder.addCase(getGlobalCertificates.fulfilled, (state, action) => {
      state.certificatesLoading = false
      state.certificates = action.payload.data.totals.map((cert) => {
        return {
          value: cert.issued_counter,
          label: cert.short_name,
          color: cert.graph_color
        }
      })
      state.totalCertificates = state.certificates.reduce(
        (n, { value }) => n + value,
        0
      )
      state.certificatesDetails = action.payload.data.details
    })
  }
})

export const dashboardFilters = DashboardFiltersSlice.actions
export default DashboardFiltersSlice.reducer
