// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import api from "@src/api/config"

export const getInvoices = createAsyncThunk(
  "accountant/dashboard/invoices",
  async ({ params = {}, global = false }) => {
    const url = `api/${global ? "dashboard-global" : "dashboard"}/invoices`

    const response = await api.get(url, { params })
    return { data: response.data, params }
  }
)

export const getPaymentMethods = createAsyncThunk(
  "accountant/dashboard/payment-methods",
  async ({ params = {}, global = false }) => {
    const url = `api/${
      global ? "dashboard-global" : "dashboard"
    }/payment-methods`

    const response = await api.get(url, { params })
    return { data: response.data, params }
  }
)

export const getCommisions = createAsyncThunk(
  "accountant/dashboard/comissions",
  async (params = {}) => {
    const url = `api/dashboard-global/commissions`

    const response = await api.get(url, { params })
    return { data: response.data, params }
  }
)

export const getPayments = createAsyncThunk(
  "accountant/dashboard/payments",
  async ({ params = {}, global = false }) => {
    const url = `api/${global ? "dashboard-global" : "dashboard"}/payments`

    const response = await api.get(url, { params })
    return { data: response.data, params }
  }
)

export const AccountantsDashboardSlice = createSlice({
  name: "accountants-dashboard",
  initialState: {
    //charts data
    invoices: [],
    paymentMethods: null,
    payments: {},
    totalCommissions: 0,
    comissions: {},
    //loading flags
    invoicesLoading: false,
    paymentMethodsLoading: false,
    paymentsLoading: false,
    commissionsLoading: false
  },
  reducers: {
    clearDashboardData: (state) => {
      state.invoices = []
      state.payments = {}
      state.totalCommissions = 0
      state.comissions = {}
    }
  },
  extraReducers: (builder) => {
    //getInvoices
    builder.addCase(getInvoices.pending, (state) => {
      state.invoicesLoading = true
    })
    builder.addCase(getInvoices.rejected, (state) => {
      state.invoicesLoading = false
    })
    builder.addCase(getInvoices.fulfilled, (state, action) => {
      state.invoicesLoading = false
      state.invoices = action.payload.data?.data?.[0] || action.payload.data
    })
    //getPaymentMethods
    builder.addCase(getPaymentMethods.pending, (state) => {
      state.paymentMethodsLoading = true
    })
    builder.addCase(getPaymentMethods.rejected, (state) => {
      state.paymentMethodsLoading = false
    })
    builder.addCase(getPaymentMethods.fulfilled, (state, action) => {
      state.paymentMethodsLoading = false
      state.paymentMethods = action.payload.data || []
    })
    //getCommisions
    builder.addCase(getCommisions.pending, (state) => {
      state.commissionsLoading = true
    })
    builder.addCase(getCommisions.rejected, (state) => {
      state.commissionsLoading = false
    })
    builder.addCase(getCommisions.fulfilled, (state, action) => {
      state.commissionsLoading = false
      state.comissions = action.payload.data
    })
    //getPayments
    builder.addCase(getPayments.pending, (state) => {
      state.paymentsLoading = true
    })
    builder.addCase(getPayments.rejected, (state) => {
      state.paymentsLoading = false
    })
    builder.addCase(getPayments.fulfilled, (state, action) => {
      state.paymentsLoading = false
      state.payments = action.payload.data
    })
  }
})

export const accountantDashboard = AccountantsDashboardSlice.actions
export default AccountantsDashboardSlice.reducer
