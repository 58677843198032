// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import api from "@src/api/config"

export const getClientsBySellerDash = createAsyncThunk(
  "dashboard/clients/seller",
  async ({ params = {} }) => {
    const response = await api.get(`api/dashboard/clients/seller`, { params })
    return { data: response.data, params }
  }
)

export const SellerDashboardSlice = createSlice({
  name: "sellers-dashboard",
  initialState: {
    clientsBySellerDash: {},
    //Loadings
    clientsBySellerDashLoading: true
  },
  reducers: {
    clearDashboardData: (state) => {
      state.clientsBySellerDash = {}
    }
  },
  extraReducers: (builder) => {
    //getClientsBySellerDash
    builder.addCase(getClientsBySellerDash.pending, (state) => {
      state.clientsBySellerDashLoading = true
    })
    builder.addCase(getClientsBySellerDash.rejected, (state) => {
      state.clientsBySellerDashLoading = false
    })
    builder.addCase(getClientsBySellerDash.fulfilled, (state, action) => {
      state.clientsBySellerDashLoading = false
      state.clientsBySellerDash = action.payload.data.data
    })
  }
})

export const sellerDashboard = SellerDashboardSlice.actions
export default SellerDashboardSlice.reducer
