// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import api from "@src/api/config"

export const getClientsAdmin = createAsyncThunk(
  "dashboard/clients/admin",
  async ({ params = {}, global = false }) => {
    const url = `api/${
      global ? "dashboard-global/clients-admin" : "dashboard/clients/admin"
    }`

    const response = await api.get(url, { params })
    return { data: response.data, params }
  }
)

export const getCertificatesByProvider = createAsyncThunk(
  "dashboard/certificates-providers",
  async (params = {}, { getState }) => {
    const state = getState()
    const businessLines = state.businessLines?.businessLineSelected?.id
    params.product = state.products.productSelected?.value
    params.business_line = businessLines
    const response = await api.get(
      `api/dashboard-global/certificates-providers`,
      { params }
    )
    return { data: response.data, params }
  }
)

export const getSalesPerSeller = createAsyncThunk(
  "dashboard/sales/seller",
  async ({ params = {}, global = false }) => {
    const url = `api/${
      global ? "dashboard-global/insured-sum" : "dashboard/sales/seller"
    }`
    const response = await api.get(url, { params })
    return { data: response.data, params }
  }
)

export const AdminDashboardSlice = createSlice({
  name: "admins-dashboard",
  initialState: {
    clientsAdmin: {},
    clientsAdminLoading: false,
    salesPerSeller: {},
    salesPerSellerLoading: false,
    providers: {},
    providersLoading: false
  },
  reducers: {
    clearDashboardData: (state) => {
      state.clientsAdmin = {}
    }
  },
  extraReducers: (builder) => {
    //getClientsAdmin
    builder.addCase(getClientsAdmin.rejected, (state) => {
      state.clientsAdminLoading = true
    })
    builder.addCase(getClientsAdmin.pending, (state) => {
      state.clientsAdminLoading = true
    })
    builder.addCase(getClientsAdmin.fulfilled, (state, action) => {
      state.clientsAdminLoading = false
      state.clientsAdmin = action.payload.data
    })
    //getCertificatesByProvider
    builder.addCase(getCertificatesByProvider.rejected, (state) => {
      state.providersLoading = false
    })
    builder.addCase(getCertificatesByProvider.pending, (state) => {
      state.providersLoading = true
    })
    builder.addCase(getCertificatesByProvider.fulfilled, (state, action) => {
      state.providersLoading = false
      state.providers = action.payload.data.data
    })
    //getSalesPerSeller
    builder.addCase(getSalesPerSeller.pending, (state) => {
      state.salesPerSellerLoading = true
    })
    builder.addCase(getSalesPerSeller.rejected, (state) => {
      state.salesPerSellerLoading = false
    })
    builder.addCase(getSalesPerSeller.fulfilled, (state, action) => {
      state.salesPerSellerLoading = false
      state.salesPerSeller = action.payload.data
    })
  }
})

export const adminDashboard = AdminDashboardSlice.actions
export default AdminDashboardSlice.reducer
